footer {
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
}

.main {
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(https://source.unsplash.com/featured/?cow) no-repeat center center fixed;
  display: flex;
  align-items: center;
  height: 100vh;
  position: relative;
  width: 100%;
  background-size: cover;
  text-align: center;
  justify-content: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
